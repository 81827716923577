import 'jquery-ui'
import '../../js/plugins/multiSelect'
import {AjaxSync} from "../shared/ajax_utilities";

export default class tacho_download
{
  _perPage

  _$table

  _$emptySearchTemplate
  _$emptyFileListTemplate

  _$mainRowTemplate
  _$subRowTemplate

  _paginationTemplates
  _$paginationContainer

  _$filters

  _searchUrl
  _fileUrl
  _currentPageNumber
  _total

  constructor ($table) {
    this._$table = $table
    this._$filters = app.DOM.content.find('form#filter')
    this._currentPageNumber = 1

    this._$paginationContainer = $('div.paging-container')
    this._paginationTemplates = {
      total: app.DOM.content.find('div#pagination_container span.total')[0].outerHTML,
      rows: app.DOM.content.find('div#pagination_container span.rows')[0].outerHTML,
      first: app.DOM.content.find('div#pagination_container button.pg-first')[0].outerHTML,
      prev: app.DOM.content.find('div#pagination_container button.pg-prev')[0].outerHTML,
      next: app.DOM.content.find('div#pagination_container button.pg-next')[0].outerHTML,
      last: app.DOM.content.find('div#pagination_container button.pg-last')[0].outerHTML,
      select: app.DOM.content.find('div#pagination_container select.paging')[0].outerHTML,
    }

    this.attachHandlers()
  }

  attachHandlers()
  {
    this._$filters.on('submit', this.catchFormSubmission.bind(this))

    this._$filters.find('select[multiple]').multiselect({
      'columns': 1,
      'placeholder': '- Not Selected -',
      'selectAll': true,
      'minHeight': 150,
      'onOptionClick': function (e) {
        var $el = $(e.element).next()

        // update text
        $el.closest('.multiselect-container').find('.multiple_count').text($el.find(':checked').length)
      }
    })

    app.DOM.content.find('input.datepicker').datepicker({
      changeMonth: true,
      changeYear: true,
      showButtonPanel: false,
      dateFormat: 'dd-mm-yy',
      minDate: '-1y',
      maxDate: 0,
    });

  }

  catchFormSubmission(e)
  {
    e.preventDefault()

    this._$table.find('div.msg').addClass('hidden')
    this._$table.find('div.data').removeClass('hidden')

    this._$table.find('tr.loading').removeClass('hidden')

    const searchData = this.getFilters()
    const $dataContainer = this._$table.find('table.download_table tbody')
    $dataContainer.hide()

    AjaxSync({
      url: this._searchUrl,
      data: searchData,
      method: 'POST'
    }, {
      done: (response) => {
        this._$table.find('tr.loading').addClass('hidden')
        this.handleSearchResponse(response.data)
        if (response.meta) {
          this.handlePaginationInfo(response.meta)
        }
      }
    });
  }

  getFilters () {
    const searchData = this._$filters.serializeArray()
    searchData.push({
      'name': '_pageNumber',
      'value': this._currentPageNumber,
    })
    return searchData
  }

  createRows(data, templateHTML, events) {
    const rows = []
    const keyReplacement = /#([a-z_0-9]+)#/g
    for (const row of data) {
      let template = templateHTML.replaceAll(keyReplacement, (m, p1) => {
        return row[p1]
      });
      const $row = $(template);
      for (const eventObject of events) {
        $row.find(eventObject.selector).on(eventObject.event, eventObject.handler)
      }
      rows.push($row)
    }

    console.log(rows);

    return rows
  }

  handleSearchResponse(data)
  {
    const $dataContainer = this._$table.find('table.download_table tbody')
    const $clearButton = this._$filters.find('a#submit_clear')

    const rows = this.createRows(data, this._$mainRowTemplate[0].outerHTML, [
      {
        'selector': '.collapse',
        'event': 'click',
        'handler': this.collapseSubrows.bind(this)
      },
      {
        'selector': '.expand',
        'event': 'click',
        'handler': this.expandSubrows.bind(this)
      },
      {
        'selector': 'a.download-disabled',
        'event': 'click',
        'handler': this.downloadDisabledWarning.bind(this)
      }
    ])

    $dataContainer.empty()
    $clearButton.removeClass('hidden')
    if (rows.length > 0) {
      $dataContainer.append(...rows)
    } else {
      this._$emptySearchTemplate.clone().appendTo($dataContainer)
    }

    $dataContainer.show()
  }

  handlePaginationInfo (meta) {
    this._$paginationContainer.empty()

    if (meta.pageNumber) {
      this._currentPageNumber = meta.pageNumber
    }

    if (meta.count) {
      this._total = meta.count
      const $total = $(this._paginationTemplates['total'].replace(/#total#/g, meta.count))
      $total.appendTo(this._$paginationContainer)

      const from = 1 + (this._currentPageNumber - 1) * this._perPage;
      let to = (this._currentPageNumber) * this._perPage;
      if (to > this._total) {
        to = this._total
      }
      $(
        this._paginationTemplates['rows'].replace(/#first#/g, from).replace(/#last#/g, to)
      ).appendTo(this._$paginationContainer)
    }

    // Previous and next buttons if needed
    if (meta.prev) {
      if (meta.prev > 1) {
        const $first = $(this._paginationTemplates['first']).on('click', this.firstPage.bind(this))
        $first.appendTo(this._$paginationContainer)
      }

      const $prev = $(this._paginationTemplates['prev']).on('click', this.previousPage.bind(this))
      $prev.appendTo(this._$paginationContainer)
    }

    const pages = Math.ceil(meta.count / meta.perPage);
    if (pages > 1) {
      const $pageSelector = $(this._paginationTemplates['select']).on('change', (e) => this.setPage(e.target.value))
      for (let i = 1; i <= pages; ++i) {
        const $o = $('<option>').val(i).text(i)
        if (i === this._currentPageNumber) {
          $o.prop('selected', true)
        }
        $pageSelector.append($o)
      }

      $pageSelector.appendTo(this._$paginationContainer)
    }

    if (meta.next) {
      const $next = $(this._paginationTemplates['next']).on('click', this.nextPage.bind(this))
      $next.appendTo(this._$paginationContainer)

      if (meta.count > (meta.next * meta.perPage)) {
        const $last = $(this._paginationTemplates['last']).on('click', this.lastPage.bind(this))
        $last.appendTo(this._$paginationContainer)
      }
    }
  }

  firstPage() {
    this.setPage(1)
  }

  previousPage() {
    this.setPage(this._currentPageNumber - 1)
  }

  nextPage() {
    this.setPage(this._currentPageNumber + 1)
  }

  lastPage() {
    const pageNumber = (this._total / this._perPage).toFixed(0)
    this.setPage(pageNumber)
  }

  setPage(pageNumber) {
    this._currentPageNumber = pageNumber
    const e = $.Event('submit')
    this.catchFormSubmission(e)
  }

  collapseSubrows(e)
  {
    const $button = $(e.currentTarget)
    const $expandButton = $button.siblings('.expand')
    $expandButton.removeClass('hidden')
    $button.addClass('hidden')

    const hideRowIds = $expandButton.data('id');
    $button.parents('tr').siblings('.row-sub-' + hideRowIds).remove()
  }

  expandSubrows(e)
  {
    const $button = $(e.currentTarget)
    $button.siblings('.collapse').removeClass('hidden')
    $button.addClass('hidden')

    const rowID = $button.data('id');
    const $row = $button.parents('tr')

    AjaxSync({
      url: this._fileUrl + rowID,
      data: this.getFilters(),
      method: 'POST'
    }, {
      done: (response) => {
        const rows = this.createRows(response.data, this._$subRowTemplate[0].outerHTML, []);

        if (rows.length > 0) {
          $row.after(...rows)
        } else {
          const $r = this._$emptySearchTemplate.clone().addClass('row-sub-' + rowID)
          $row.after($r)
        }
      }
    });
  }

  downloadDisabledWarning()
  {
    alert('Downloads for demonstration companies are disabled');
  }
}
